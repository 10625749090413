import React, { useEffect, useState } from "react";
import styles from "./index.module.less";
import { Form, Input, App, Checkbox, Button, Divider } from "antd";
import Link from "next/link";
import Image from "next/image";
import Cookies from "js-cookie";
import { isEmailValid, ls_saveItem } from "@/shared/utils";
import { ga_logEvent } from "@/shared/ga";
import { useUserProvider } from "@/context/UserProvider";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithRedirect,
  sendEmailVerification,
  signOut,
  // sendEmailVerification,
} from "firebase/auth";
import { useRouter } from "next/router";
import useScreenSize from "@/lib/hooks/utils/useScreenSize";

import { emailNotification, userUpdate, getUserInfoApi } from "@/lib/service";
import { EventName } from "@/shared/event-types";
import { useTranslation } from "next-i18next";
import { useConfigProvider } from "@/context/ConfigProvider";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const Login: React.FC<any> = ({ data }) => {
  const provider = new GoogleAuthProvider();
  const { width } = useScreenSize();
  const { isIPhone } = useConfigProvider();

  const onGoogleAuth = () => {
    const auth = getAuth();
    if (width < 900 && !isIPhone) {
      signInWithRedirect(auth, provider);
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log(result);
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          Cookies.set("accessToken", token, { expires: 7 });

          result.user.getIdToken().then((id) => {
            getToken(id, "google");
            ga_logEvent(EventName.login, { method: "Google" });
          });
        })
        .catch((error) => {
          onAddToken(undefined);
          message.error(t("Google_Login_Failed_Error_Msg"));
        });
    }
  };
  const { message } = App.useApp();
  const { t } = useTranslation("common");
  const [isReadProtocol, setIsReadProtocol] = useState(true);

  const router = useRouter();
  const [isMailReception, setIsMailReception] = useState(true);

  const [loading, setLoading] = useState(false);

  const getToken = async (id: string, signInMethod: string) => {
    const res: any = await userUpdate(id, router.locale);

    if (res?.data?.first_login) {
      if (res.code == 0) {
        ga_logEvent(EventName.user_register_Success);
      } else {
        ga_logEvent(EventName.user_register_Fail);
      }
    }

    if (res.code == 0) {
      onAddToken(res.data.token, signInMethod, id);
      getUserInfoApi().then((res) => {
        setUserInfo(res.data);
        localStorage.setItem("uif", JSON.stringify(res.data));
      });
      onOpenLoginModal(false);
      onChangedLoginResult(true);
      ga_logEvent(EventName.user_Login_Success);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const {
    openLogin,
    onOpenLoginModal,
    isLoginView,
    onChangedLoginView,
    openLoginResult,
    onChangedLoginResult,
    onAddToken,
    setUserInfo,
  } = useUserProvider();

  const onFinish = (values: any) => {
    const auth = getAuth();
    setLoading(true);

    if (isLoginView) {
      signInWithEmailAndPassword(auth, values?.email, values?.password)
        .then((result) => {
          const tempUser = result?.user;

          if (tempUser?.emailVerified) {
            tempUser?.getIdToken().then((id) => {
              getToken(id, "email");
              ga_logEvent(EventName.login, { method: "Email" });
            });
          } else {
            sendEmailVerification(tempUser)
              ?.then()
              .then(() => {
                message.success(
                  t(
                    "A verification email has been sent to x111x. Please check your inbox."
                  ).replace("x111x", values?.email)
                );
                onChangedLoginView(true);
              })
              .catch((error) => {
                message.success(
                  t(
                    "A verification email has been sent to x111x. Please check your inbox."
                  ).replace("x111x", values?.email)
                );
              })
              .finally(() => {
                setLoading(false);
                signOut(getAuth());
                onAddToken(undefined);
              });
          }
        })
        .catch((error) => {
          onAddToken(undefined);
          message.error(t("Login_Failed_Error_Msg"));
          setLoading(false);
        });
    } else {
      if (!isReadProtocol) {
        message.warning(t("Non-consent agreement"));
        setLoading(false);
        return;
      }
      createUserWithEmailAndPassword(auth, values?.email, values?.password)
        .then((result) => {
          sendEmailVerification(result?.user)
            ?.then()
            .then(() => {
              message.success(
                t(
                  "A verification email has been sent to x111x. Please check your inbox."
                ).replace("x111x", values?.email)
              );
              onChangedLoginView(true);
            })
            .catch((error) => {
              message.success(
                t(
                  "A verification email has been sent to x111x. Please check your inbox."
                ).replace("x111x", values?.email)
              );
            })
            .finally(() => {
              setLoading(false);
              signOut(getAuth());
              onAddToken(undefined);
            });

          // // sendEmailVerification(auth.currentUser);
          // result.user.getIdToken().then((id) => {
          //   getToken(id, "email");
          //   ga_logEvent(EventName.sign_up, { method: "Email" });
          // });
        })
        .catch((error) => {
          onAddToken(undefined);
          message.error(t("Sign_Up_Failed_Error_Msg"));
          setLoading(false);
        });
    }
    emailNotification(isMailReception).catch((error) => {
      message.error(error.message);
    });
  };

  useEffect(() => {
    if (router.query.type) {
      onChangedLoginView(false);
    }
  }, []);
  return (
    <div className={styles.container}>
      <h1>{isLoginView ? "Sign in" : "Sign up"}</h1>
      <div className={styles.form}>
        <Form
          name="normal_login"
          className={styles.homeContent}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ padding: "20px 24px" }}
        >
          <div className={styles.titleContainer}>
            {/* {isLoginView ? t("Sign in") : t("CreateAnAccount")} */}
          </div>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t("pleaseInputYourEmail") },
              () => ({
                validator(_, value) {
                  if (!value || isEmailValid(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("pleaseInputYourEmail")));
                },
              }),
            ]}
          >
            <Input
              className={styles.inputItem}
              prefix={
                <UserOutlined style={{ color: "#B3B3B3", fontSize: "20px" }} />
              }
              placeholder={t("EmailAddress")}
              // type="email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: t("pleaseInputYourPassword") },
              () => ({
                validator(_, value) {
                  if (
                    !value ||
                    (`${value}`?.length >= 6 && `${value}`?.length <= 16)
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("PasswordShouldBe_6_16_Characters"))
                  );
                },
              }),
            ]}
          >
            <Input
              className={styles.inputItem}
              prefix={
                <LockOutlined style={{ color: "#B3B3B3", fontSize: "20px" }} />
              }
              type="password"
              placeholder={t("Password")}
            />
          </Form.Item>
          {!isLoginView && (
            <Form.Item
              name="repassword"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("theNewPasswordThatYouEnteredDoNotMatch"))
                    );
                  },
                }),
              ]}
            >
              <Input
                className={styles.inputItem}
                prefix={
                  <LockOutlined
                    style={{ color: "#B3B3B3", fontSize: "20px" }}
                  />
                }
                type="password"
                placeholder={t("ConfirmPassword")}
              />
            </Form.Item>
          )}

          {!isLoginView && (
            <>
              <div className={styles.agreeDesc}>
                <Checkbox
                  checked={isReadProtocol}
                  onChange={() => setIsReadProtocol(!isReadProtocol)}
                />
                <div>
                  <span>{t("ByClickingContinue_Desc")}</span>
                  <span>
                    <Link
                      href={"/terms-us"}
                      className={styles.link}
                      onClick={() => onOpenLoginModal(false)}
                    >
                      {t("Terms")},
                    </Link>
                  </span>
                  <span>
                    <Link
                      href={"/privacy-policy"}
                      className={styles.link}
                      onClick={() => onOpenLoginModal(false)}
                    >
                      {t("privacyPolicy")}
                    </Link>
                  </span>
                </div>
              </div>
              <div className={styles.agreeDesc}>
                <Checkbox
                  checked={isMailReception}
                  onChange={() => setIsMailReception(!isMailReception)}
                />
                <span>{t("EmailNotifications")}</span>
              </div>
            </>
          )}

          <Form.Item name="btn">
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginFormButton}
              loading={loading}
            >
              {isLoginView ? t("Next") : "Create an account"}
            </Button>
          </Form.Item>

          {isLoginView && (
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox className={styles.staySignedIn}>
                {t("StaySignedIn")}
              </Checkbox>
              <Link
                className={styles.forgotItem}
                href="/forgot-password"
                onClick={() => {
                  onOpenLoginModal(false);
                  ga_logEvent(EventName.login_Forgot_Password);
                }}
              >
                {t("ForgotPassword")}?
              </Link>
            </Form.Item>
          )}

          {isLoginView ? (
            <Form.Item name="googlebtn">
              <Button
                className={styles.createFormButton}
                onClick={() => onChangedLoginView(false)}
              >
                {t("CreateAnAccount")}
              </Button>
            </Form.Item>
          ) : (
            <Form.Item name="googlebtn">
              <Button
                className={styles.createFormButton}
                onClick={() => onChangedLoginView(true)}
              >
                Back to login
              </Button>
            </Form.Item>
          )}

          <Form.Item name="">
            <Divider className={styles.googleLoginText}>
              {isLoginView && <span>{t("Login")}</span>}
              {isLoginView && <span> {t("with others")}</span>}
              {!isLoginView && <span> {t("or create an account with")}</span>}
            </Divider>

            <Button className={styles.googleFormButton} onClick={onGoogleAuth}>
              <Image
                src={`/images/login/google_icon.webp`}
                width={20}
                height={20}
                alt={"google login"}
                loading="lazy"
              />
              <span>{t("Login with")}</span>
              <span>google</span>
            </Button>

            {/* {!isLoginView && (
              <div className={styles.accountContainer}>
                <span>{t("AlreadyHaveAnAccount")} </span>
                <span onClick={() => onChangedLoginView(true)}>
                  {t("Sign in")}
                </span>
              </div>
            )} */}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
